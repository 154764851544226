<template>
  <div class="container-fluid p-4">
    <router-link
      :to="{
        name: 'reports'
      }"
      v-slot="{ href, route, navigate }"
      custom
    >
      <a :href="href" @click="navigate" class="mr-2"
        ><i class="fa fa-chevron-left mr-1"></i>Back</a
      >
    </router-link>
    <div class="d-flex flex-column pt-4"></div>
    <div class="d-flex justify-content-between">
      <h1>Scheduled Report</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column pt-4">
      <!-- query form -->
      <div class="d-flex flex-column mb-5">
        <b-card-group>
          <b-card class="bg-surface-2">
            <b-form @click="getData">
              <b-row class="d-flex flex-row justify-content-between">
                <b-col>
                  <div>Report Type</div>
                </b-col>
                <b-col>
                  <div>Select a date</div>
                </b-col>
              </b-row>
              <b-row class="d-flex flex-row justify-content-between">
                <b-col>
                  <b-form-select
                    id="input-gender"
                    v-model="scheduleForm.report_type"
                    :options="scheduleOptions"
                    @change="getData"
                    :state="validateFormSearch('report_type')"
                    aria-describedby="scheduleForm.report_type_feedback"
                  ></b-form-select>
                  <b-form-invalid-feedback
                    class="invalid-feedback"
                    id="scheduleForm.report_type_feedback"
                  >
                    This field is required
                  </b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <date-range-picker
                    ref="picker"
                    class="w-100 ml-auto"
                    v-model="queryForm.dateRange"
                    :locale-data="{
                      firstDay: 1,
                      format: 'yyyy-mm-dd'
                    }"
                    :time-picker-increment="1"
                    :timePicker="false"
                    :time-picker-seconds="false"
                    :singleDatePicker="true"
                  >
                  </date-range-picker>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-end mt-1" no-gutters>
                <b-button
                  size="sm"
                  variant="dark"
                  @click="clearFilter"
                  class="d-flex justify-content-end mr-1"
                  >Clear
                </b-button>
                <b-button size="sm" variant="light"> Search </b-button>
              </b-row>
            </b-form>
          </b-card>
        </b-card-group>
      </div>

      <div class="d-flex justify-content-between">
        <!-- Main table element -->
        <b-table
          :busy="busy"
          :items="result"
          :fields="resultTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchTerms"
          class="tableBorder"
          :filter-included-fields="['name']"
          id="temp-target"
          small
          show-empty
          sticky-header
          stacked="md"
          fixed
          striped
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <div class="my-5">
                <strong>Please wait. It may take up to a minute...</strong>
              </div>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>

          <template #cell(index)="row">
            <div v-if="perPage != 'all'">
              {{ row.index + 1 + (currentPage - 1) * perPage }}
            </div>
            <div v-else>
              {{ row.index + 1 }}
            </div>
          </template>

          <template #cell(report_date)="row">
            {{ row.item.report_date | date }}
          </template>

          <template #cell(actions)="row">
            <b-button-group size="sm">
              <!--pdf content  -->

              <b-button @click="getGeneratePDF(row.item, row.index)">
                <i class="fas fa-file-pdf mr-1"></i>Export as PDF
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            @change="handlePerPageChange"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  components: {
    DateRangePicker
  },
  data: function () {
    let yesterday = moment().subtract(1, "hours").toDate();
    yesterday.setMinutes(0);
    yesterday.setSeconds(0);
    yesterday.setMilliseconds(0);

    let today = moment().toDate();
    return {
      pageTitle: "Reports",
      busy: false,

      result: [],
      resultTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        {
          key: "report_date",
          label: "Report Date",
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],
      // scheduleForm: {
      //   report_type: null
      // },
      scheduleForm: {
        report_type: "DAILY_REPORT"
      },
      scheduleOptions: [
        // { value: null, text: "" },
        { value: "DAILY_REPORT", text: "DAILY REPORT" },
        { value: "FORM_REPORT", text: "FORM REPORT" }
      ],
      indexNumbering: 0,
      sortBy: "created",
      sortDesc: true,
      sortDirection: "desc",
      filter: null,
      // table search
      searchTerms: "",
      search: false,
      // pagination
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],
      pageOptions: [5, 10, 15],
      queryForm: {
        dateRange: {
          startDate: null
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCurrentScMode: "session/getScMode"
    })
  },
  validations: {
    scheduleForm: {
      report_type: { required }
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getData();
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  mounted() {
    this.getData();
    //this.getData("all");
  },
  filters: {},
  methods: {
    validateFormSearch(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.scheduleForm[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitValidationForm() {
      let $this = this;
      $this.$v.scheduleForm.$touch();
      if ($this.$v.scheduleForm.$anyError) {
        return false;
      } else {
        return true;
      }
    },

    getData() {
      this.busy = true;
      let $this = this;
      $this.result = [];
      let query = {};
      let API_URL = null;

      let noValidate = $this.onSubmitValidationForm();
      if (noValidate) {
        if (this.perPage === "all") {
          query = {
            report_type: $this.scheduleForm.report_type
          };
        } else {
          if ($this.queryForm.dateRange.startDate != null) {
            query = {
              report_type: $this.scheduleForm.report_type,
              report_date: moment($this.queryForm.dateRange.startDate).format(
                "YYYY-MM-DD"
              ),
              page_index: this.currentPage,
              page_size: this.perPage
            };
          } else {
            query = {
              report_type: $this.scheduleForm.report_type,
              page_index: this.currentPage,
              page_size: this.perPage
            };
          }
        }

        // }
        query = Object.entries(query).reduce(
          (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
          {}
        );

        let queryString = Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&");

        API_URL = $this.getAPIServerURL + "/api/visitorservice/report/all/?";

        API_URL += queryString;

        const client = $this.$root.getAjaxFetchClient();
        client
          .getRequest(API_URL)
          .then((data) => {
            let error = data.detail;
            let validUrl = "";
            $this.result = [];
            let result = data.result;
            if (error != undefined) {
              $this.busy = false;
            } else {
              $this.result = [];
              if (result !== undefined) {
                for (let vms_url in result) {
                  validUrl = vms_url;
                  if (this.perPage == "all") {
                    this.result = result[validUrl];
                  } else {
                    this.result = result[validUrl]["reports"];
                  }

                  // $this.result = result[validUrl];
                  // $this.result = [];
                }
                $this.busy = false;
              }
            }
            if (result[validUrl]["total"] !== undefined) {
              this.totalRows = parseInt(result[validUrl]["total"]);
            }

            if (result[validUrl]["page"] !== undefined) {
              this.currentPage = parseInt(result[validUrl]["page"]);
            }

            if (result[validUrl]["size"] !== undefined) {
              this.perPage = parseInt(result[validUrl]["size"]);
            }
          })
          .catch((err) => {
            console.log("error", err);
            $this.busy = false;
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Please try again later",
              message_type: "danger"
            });
          });
      } else {
        $this.busy = false;
        $this.result = [];
      }
    },

    getGeneratePDF(data) {
      let $this = this;
      let query = {
        report_date: data.report_date,
        report_type: $this.scheduleForm.report_type
      };
      query = Object.entries(query).reduce(
        (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
        {}
      );
      let API_URL = $this.getAPIServerURL + "/api/visitorservice/report/?";
      let queryString = Object.keys(query)
        .map((key) => key + "=" + query[key])
        .join("&");
      API_URL += queryString;
      const client = $this.$root.getAjaxFetchClient();
      client.responseType = "blob";
      client
        .getRequest(API_URL)
        .then((result) => {
          result;
          let fileName = data.report_date + " Report.pdf";
          const link = document.createElement("a");
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(result);
          // some browser needs the anchor to be in the doc
          link.download = fileName;
          document.body.append(link);
          link.click();
          link.remove();
          // in case the Blob uses a lot of memory
          setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        })
        .catch((err) => {
          console.log("error", err);
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Please try again later",
            message_type: "danger"
          });
        });
    },

    clearFilter: function () {
      this.result = [];
      this.scheduleForm = {
        report_type: "DAILY_REPORT"
      };

      this.queryForm = {
        dateRange: {
          startDate: null
        }
      };
      this.$router.push({
        name: this.$route.query.parents_url
      });
      // this.getData();
    },
    /* handle page change */
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
